<template>
	<header class="dm-header">
		<div class="dm-header-assets">
			<vue-particles
				id="tsparticles"
				:particlesInit="particlesInit"
				:options="{
					fpsLimit: 120,
					interactivity: { events: { resize: true }},
					style: { position: 'absolute' },
					particles: {
						color: { value: '#ffffff' },
						move: {
							direction: 'none',
							enable: true,
							outModes: 'bounce',
							random: false,
							speed: .25,
							straight: false
						},
						number: {
							density: {
								enable: true,
								area: 800
							},
							value: 100
						},
						opacity: { value: 0.5 },
						shape: { type: 'circle' },
						size: {
							value: {
								min: 1,
								max: 3
							}
						}
					},
					detectRetina: true
				}"
			/>
			<svg class="dm-header-planet-left" alt="Planet Asset" width="149" height="125" viewBox="0 0 149 125" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.402 81.2845C20.4813 84.6645 23.9834 94.5835 32.9697 104.151C42.0335 113.8 51.716 117.809 53.9374 118.691C56.5401 119.723 79.0546 128.25 99.9431 116.826C100.859 116.326 101.747 115.801 102.603 115.257C111.048 109.905 116.635 102.779 119.121 99.4352C119.33 99.1561 119.515 98.906 119.679 98.683C120.65 97.3618 122.569 94.6506 124.643 90.8899C125.001 90.2447 125.361 89.5687 125.722 88.8637C127.079 86.2159 128.849 82.709 130.305 78.3503C131.225 75.6064 132.018 72.5236 132.505 69.1055C134.094 57.9758 131.597 49.2113 130.162 44.3741C127.149 34.2032 122.395 27.367 120.542 24.8624C118.706 22.3795 115.461 18.057 109.935 13.8016C108.289 12.5348 100.735 6.88205 89.2138 4.23239C85.0875 3.28453 81.7545 3.01993 80.0853 2.9275C77.8171 2.80245 70.8921 2.56503 62.5712 4.85403C57.0635 6.37096 52.4912 8.56935 48.8686 10.7351C45.2478 12.8972 42.5749 15.0286 40.8643 16.4114C39.6556 17.3882 37.9576 18.762 36.0132 20.5526C34.0094 22.3976 31.7448 24.6866 29.4856 27.445C25.3018 32.5522 21.1378 39.2669 18.6897 47.7415C18.3066 49.0609 17.9667 50.4238 17.6753 51.8302C17.0493 54.8459 14.8099 66.8962 19.402 81.2845Z" fill="var(--primary-color)"/>
				<path d="M19.402 81.2845C20.4813 84.6645 23.9834 94.5835 32.9697 104.151C42.0335 113.8 51.716 117.809 53.9374 118.691C56.5401 119.723 79.0546 128.25 99.9431 116.826C100.859 116.326 101.747 115.801 102.603 115.257C111.048 109.905 116.635 102.779 119.121 99.4352C119.33 99.1561 119.515 98.906 119.679 98.683C120.65 97.3618 122.569 94.6506 124.643 90.8899C125.001 90.2447 125.361 89.5687 125.722 88.8637C127.079 86.2159 128.849 82.709 130.305 78.3503C131.225 75.6064 132.018 72.5236 132.505 69.1055C134.094 57.9758 131.597 49.2113 130.162 44.3741C127.149 34.2032 122.395 27.367 120.542 24.8624C118.706 22.3795 115.461 18.057 109.935 13.8016C108.289 12.5348 100.735 6.88205 89.2138 4.23239C85.0875 3.28453 81.7545 3.01993 80.0853 2.9275C77.8171 2.80245 70.8921 2.56503 62.5712 4.85403C57.0635 6.37096 52.4912 8.56935 48.8686 10.7351C45.2478 12.8972 42.5749 15.0286 40.8643 16.4114C39.6556 17.3882 37.9576 18.762 36.0132 20.5526C34.0094 22.3976 31.7448 24.6866 29.4856 27.445C25.3018 32.5522 21.1378 39.2669 18.6897 47.7415C18.3066 49.0609 17.9667 50.4238 17.6753 51.8302C17.0493 54.8459 14.8099 66.8962 19.402 81.2845Z" fill="var(--primary-color)"/>
				<path d="M31.3522 77.2313C32.1987 79.871 34.9454 87.6172 41.9936 95.0889C49.1024 102.624 56.6965 105.755 58.4388 106.444C60.4802 107.25 78.1386 113.909 94.5217 104.988C95.2398 104.597 95.9367 104.187 96.6082 103.762C103.232 99.5827 107.614 94.0175 109.563 91.4061C109.727 91.1882 109.872 90.9929 110.001 90.8188C110.762 89.787 112.268 87.6696 113.894 84.7327C114.175 84.2289 114.457 83.7009 114.741 83.1503C115.804 81.0825 117.193 78.3438 118.335 74.9398C119.056 72.797 119.678 70.3895 120.061 67.7201C121.306 59.0284 119.348 52.1837 118.222 48.4061C115.859 40.4631 112.131 35.1244 110.678 33.1683C109.237 31.2293 106.692 27.8537 102.358 24.5304C101.068 23.5411 95.1425 19.1266 86.1066 17.0573C82.8703 16.3171 80.2562 16.1104 78.947 16.0382C77.168 15.9406 71.7366 15.7552 65.2104 17.5428C60.8907 18.7274 57.3045 20.4443 54.4633 22.1356C51.6234 23.8241 49.527 25.4886 48.1854 26.5685C47.2374 27.3314 45.9056 28.4042 44.3806 29.8026C42.809 31.2435 41.0329 33.0311 39.261 35.1852C35.9795 39.1737 32.7136 44.4176 30.7936 51.0358C30.4931 52.0662 30.2265 53.1306 29.9979 54.2289C29.507 56.584 27.7506 65.9948 31.3522 77.2313Z" fill="var(--primary-color)"/>
				<path d="M0.030777 11.1482C0.0723114 8.97309 0.115646 6.65463 1.63977 4.49218C3.02304 2.53109 4.91194 1.70929 6.28436 1.13421C8.78363 0.0856363 11.4797 -0.262678 16.3753 0.201741C22.4664 0.778637 26.9954 2.14105 27.7683 2.37871C32.7831 3.91709 39.9865 6.46596 48.6961 10.7129L48.7448 10.7528C45.1097 12.917 42.4262 15.0505 40.7089 16.4346C39.4954 17.4125 37.7907 18.7876 35.8386 20.5799C34.1989 20.7124 30.822 21.282 29.5633 23.7746C28.9096 25.0717 29.0613 26.4287 29.2852 27.4791C29.3159 27.6188 29.3466 27.753 29.3773 27.88C30.8707 34.0898 34.56 38.89 36.3117 41.1287C40.8425 46.914 43.1088 49.8075 46.4659 53.3197C50.0324 57.0477 52.8368 59.5258 57.7342 63.8308C61.3369 66.9965 65.2248 70.4143 70.18 74.2802C72.9953 76.4771 81.6452 83.1006 94.017 90.2029C94.017 90.2029 102.103 94.8471 114.448 98.7874C114.746 98.8817 116.198 99.3353 118.163 99.4713C118.547 99.4986 118.918 99.5239 119.275 99.5403C120.913 99.6219 122.307 99.5439 123.611 98.6622C123.811 98.5262 126.22 96.8408 126.088 94.0598C126.014 92.5087 125.189 91.4166 124.819 90.9866C125.178 90.3408 125.54 89.6641 125.902 88.9584C127.264 86.3079 129.041 82.7976 130.504 78.4346C134.54 83.1985 137.599 87.5162 139.813 90.9086C140.959 92.6665 144.134 97.63 146.995 104.426C148.129 107.123 148.706 108.905 148.892 111.144C149.051 113.054 149.418 117.462 146.581 120.847C144.732 123.053 142.381 123.803 140.721 124.33C138.478 125.042 135.757 125.388 129.328 124.33C122.984 123.286 118.298 121.742 117.357 121.428C110.979 119.296 106.008 117.003 102.692 115.378C102.075 115.075 101.515 114.795 101.013 114.543C88.8722 108.453 82.801 105.409 75.1731 100.529C72.2928 98.684 61.7558 91.8374 49.3353 80.8728C40.2954 72.8942 34.4174 66.4032 28.366 59.7254C28.366 59.7254 23.8587 54.7492 18.4466 47.7956C13.7785 41.7999 8.44052 34.3347 4.73677 27.3195C2.80453 23.6622 1.92871 20.7922 1.33098 18.7985C0.0921783 14.6659 0.00550842 12.4562 0.030777 11.1482Z" fill="#FEFFF2"/>
			</svg>
			<svg class="dm-header-planet-right" alt="Planet Asset" width="149" height="125" viewBox="0 0 149 125" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.402 81.2845C20.4813 84.6645 23.9834 94.5835 32.9697 104.151C42.0335 113.8 51.716 117.809 53.9374 118.691C56.5401 119.723 79.0546 128.25 99.9431 116.826C100.859 116.326 101.747 115.801 102.603 115.257C111.048 109.905 116.635 102.779 119.121 99.4352C119.33 99.1561 119.515 98.906 119.679 98.683C120.65 97.3618 122.569 94.6506 124.643 90.8899C125.001 90.2447 125.361 89.5687 125.722 88.8637C127.079 86.2159 128.849 82.709 130.305 78.3503C131.225 75.6064 132.018 72.5236 132.505 69.1055C134.094 57.9758 131.597 49.2113 130.162 44.3741C127.149 34.2032 122.395 27.367 120.542 24.8624C118.706 22.3795 115.461 18.057 109.935 13.8016C108.289 12.5348 100.735 6.88205 89.2138 4.23239C85.0875 3.28453 81.7545 3.01993 80.0853 2.9275C77.8171 2.80245 70.8921 2.56503 62.5712 4.85403C57.0635 6.37096 52.4912 8.56935 48.8686 10.7351C45.2478 12.8972 42.5749 15.0286 40.8643 16.4114C39.6556 17.3882 37.9576 18.762 36.0132 20.5526C34.0094 22.3976 31.7448 24.6866 29.4856 27.445C25.3018 32.5522 21.1378 39.2669 18.6897 47.7415C18.3066 49.0609 17.9667 50.4238 17.6753 51.8302C17.0493 54.8459 14.8099 66.8962 19.402 81.2845Z" fill="var(--primary-color)"/>
				<path d="M19.402 81.2845C20.4813 84.6645 23.9834 94.5835 32.9697 104.151C42.0335 113.8 51.716 117.809 53.9374 118.691C56.5401 119.723 79.0546 128.25 99.9431 116.826C100.859 116.326 101.747 115.801 102.603 115.257C111.048 109.905 116.635 102.779 119.121 99.4352C119.33 99.1561 119.515 98.906 119.679 98.683C120.65 97.3618 122.569 94.6506 124.643 90.8899C125.001 90.2447 125.361 89.5687 125.722 88.8637C127.079 86.2159 128.849 82.709 130.305 78.3503C131.225 75.6064 132.018 72.5236 132.505 69.1055C134.094 57.9758 131.597 49.2113 130.162 44.3741C127.149 34.2032 122.395 27.367 120.542 24.8624C118.706 22.3795 115.461 18.057 109.935 13.8016C108.289 12.5348 100.735 6.88205 89.2138 4.23239C85.0875 3.28453 81.7545 3.01993 80.0853 2.9275C77.8171 2.80245 70.8921 2.56503 62.5712 4.85403C57.0635 6.37096 52.4912 8.56935 48.8686 10.7351C45.2478 12.8972 42.5749 15.0286 40.8643 16.4114C39.6556 17.3882 37.9576 18.762 36.0132 20.5526C34.0094 22.3976 31.7448 24.6866 29.4856 27.445C25.3018 32.5522 21.1378 39.2669 18.6897 47.7415C18.3066 49.0609 17.9667 50.4238 17.6753 51.8302C17.0493 54.8459 14.8099 66.8962 19.402 81.2845Z" fill="var(--primary-color)"/>
				<path d="M31.3522 77.2313C32.1987 79.871 34.9454 87.6172 41.9936 95.0889C49.1024 102.624 56.6965 105.755 58.4388 106.444C60.4802 107.25 78.1386 113.909 94.5217 104.988C95.2398 104.597 95.9367 104.187 96.6082 103.762C103.232 99.5827 107.614 94.0175 109.563 91.4061C109.727 91.1882 109.872 90.9929 110.001 90.8188C110.762 89.787 112.268 87.6696 113.894 84.7327C114.175 84.2289 114.457 83.7009 114.741 83.1503C115.804 81.0825 117.193 78.3438 118.335 74.9398C119.056 72.797 119.678 70.3895 120.061 67.7201C121.306 59.0284 119.348 52.1837 118.222 48.4061C115.859 40.4631 112.131 35.1244 110.678 33.1683C109.237 31.2293 106.692 27.8537 102.358 24.5304C101.068 23.5411 95.1425 19.1266 86.1066 17.0573C82.8703 16.3171 80.2562 16.1104 78.947 16.0382C77.168 15.9406 71.7366 15.7552 65.2104 17.5428C60.8907 18.7274 57.3045 20.4443 54.4633 22.1356C51.6234 23.8241 49.527 25.4886 48.1854 26.5685C47.2374 27.3314 45.9056 28.4042 44.3806 29.8026C42.809 31.2435 41.0329 33.0311 39.261 35.1852C35.9795 39.1737 32.7136 44.4176 30.7936 51.0358C30.4931 52.0662 30.2265 53.1306 29.9979 54.2289C29.507 56.584 27.7506 65.9948 31.3522 77.2313Z" fill="var(--primary-color)"/>
				<path d="M0.030777 11.1482C0.0723114 8.97309 0.115646 6.65463 1.63977 4.49218C3.02304 2.53109 4.91194 1.70929 6.28436 1.13421C8.78363 0.0856363 11.4797 -0.262678 16.3753 0.201741C22.4664 0.778637 26.9954 2.14105 27.7683 2.37871C32.7831 3.91709 39.9865 6.46596 48.6961 10.7129L48.7448 10.7528C45.1097 12.917 42.4262 15.0505 40.7089 16.4346C39.4954 17.4125 37.7907 18.7876 35.8386 20.5799C34.1989 20.7124 30.822 21.282 29.5633 23.7746C28.9096 25.0717 29.0613 26.4287 29.2852 27.4791C29.3159 27.6188 29.3466 27.753 29.3773 27.88C30.8707 34.0898 34.56 38.89 36.3117 41.1287C40.8425 46.914 43.1088 49.8075 46.4659 53.3197C50.0324 57.0477 52.8368 59.5258 57.7342 63.8308C61.3369 66.9965 65.2248 70.4143 70.18 74.2802C72.9953 76.4771 81.6452 83.1006 94.017 90.2029C94.017 90.2029 102.103 94.8471 114.448 98.7874C114.746 98.8817 116.198 99.3353 118.163 99.4713C118.547 99.4986 118.918 99.5239 119.275 99.5403C120.913 99.6219 122.307 99.5439 123.611 98.6622C123.811 98.5262 126.22 96.8408 126.088 94.0598C126.014 92.5087 125.189 91.4166 124.819 90.9866C125.178 90.3408 125.54 89.6641 125.902 88.9584C127.264 86.3079 129.041 82.7976 130.504 78.4346C134.54 83.1985 137.599 87.5162 139.813 90.9086C140.959 92.6665 144.134 97.63 146.995 104.426C148.129 107.123 148.706 108.905 148.892 111.144C149.051 113.054 149.418 117.462 146.581 120.847C144.732 123.053 142.381 123.803 140.721 124.33C138.478 125.042 135.757 125.388 129.328 124.33C122.984 123.286 118.298 121.742 117.357 121.428C110.979 119.296 106.008 117.003 102.692 115.378C102.075 115.075 101.515 114.795 101.013 114.543C88.8722 108.453 82.801 105.409 75.1731 100.529C72.2928 98.684 61.7558 91.8374 49.3353 80.8728C40.2954 72.8942 34.4174 66.4032 28.366 59.7254C28.366 59.7254 23.8587 54.7492 18.4466 47.7956C13.7785 41.7999 8.44052 34.3347 4.73677 27.3195C2.80453 23.6622 1.92871 20.7922 1.33098 18.7985C0.0921783 14.6659 0.00550842 12.4562 0.030777 11.1482Z" fill="#FEFFF2"/>
			</svg>
			<svg class="dm-header-cloud-left" alt="Cloud Asset" width="474" height="85" viewBox="0 0 474 85" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M-60.6873 98.1276C-59.2329 108.433 -53.0132 118.338 -44.1942 123.83C-35.2514 129.385 -24.5139 130.65 -14.2095 129.724C-6.81395 129.076 0.45787 127.348 7.57497 125.219C11.0716 124.17 14.5683 122.966 18.003 121.701C19.674 121.084 21.314 120.467 22.985 119.85C24.3156 119.326 26.327 118.06 27.7504 117.999C40.066 117.382 69.3699 141.141 120.922 117.999C143.883 115.685 171.392 141.881 198.592 121.732C202.893 118.554 209.02 111.642 211 106.86C216.57 93.4375 214.775 75.4485 202.305 65.9758C196.333 61.4709 189.401 59.2801 181.82 59.7738C180.52 59.0024 181.263 40.9209 161.923 42.4328C155.796 25.6164 143.016 18.9824 128.38 31.2939C114.177 -3.23377 58.9109 -12.3979 38.3022 20.1549C38.1475 20.3709 32.2991 19.507 31.6183 19.4761C29.1738 19.4144 26.7602 19.507 24.3465 19.8155C19.5812 20.4018 14.9086 21.6977 10.5146 23.6108C1.20052 27.7146 -5.69998 34.2869 -11.734 42.2786C-12.0744 42.7414 -12.4457 43.2042 -12.9099 43.5436C-13.4359 43.9139 -14.0239 44.0682 -14.6427 44.2533C-20.0579 45.9813 -24.8542 49.4062 -28.4128 53.7878C-32.188 58.4779 -33.7351 64.0628 -35.5918 69.6477C-42.8017 68.1666 -49.2999 69.4625 -54.22 75.0783C-60.0065 81.6814 -61.8012 89.9816 -60.6873 98.0659V98.1276Z" fill="#FEFFF2"/>
				<path d="M70.3127 98.1276C71.7671 108.433 77.9868 118.338 86.8058 123.83C95.7486 129.385 106.486 130.65 116.79 129.724C124.186 129.076 131.458 127.348 138.575 125.219C142.072 124.17 145.568 122.966 149.003 121.701C150.674 121.084 152.314 120.467 153.985 119.85C155.316 119.326 157.327 118.06 158.75 117.999C171.066 117.382 200.37 141.141 251.922 117.999C274.883 115.685 302.392 141.881 329.592 121.732C333.893 118.554 340.02 111.642 342 106.86C347.57 93.4375 345.775 75.4485 333.305 65.9758C327.333 61.4709 320.401 59.2801 312.82 59.7738C311.52 59.0024 312.263 40.9209 292.923 42.4328C286.796 25.6164 274.016 18.9824 259.38 31.2939C245.177 -3.23377 189.911 -12.3979 169.302 20.1549C169.148 20.3709 163.299 19.507 162.618 19.4761C160.174 19.4144 157.76 19.507 155.347 19.8155C150.581 20.4018 145.909 21.6977 141.515 23.6108C132.201 27.7146 125.3 34.2869 119.266 42.2786C118.926 42.7414 118.554 43.2042 118.09 43.5436C117.564 43.9139 116.976 44.0682 116.357 44.2533C110.942 45.9813 106.146 49.4062 102.587 53.7878C98.812 58.4779 97.2649 64.0628 95.4082 69.6477C88.1983 68.1666 81.7001 69.4625 76.78 75.0783C70.9935 81.6814 69.1988 89.9816 70.3127 98.0659V98.1276Z" fill="#FEFFF2"/>
				<path d="M199.313 98.1276C200.767 108.433 206.987 118.338 215.806 123.83C224.749 129.385 235.486 130.65 245.79 129.724C253.186 129.076 260.458 127.348 267.575 125.219C271.072 124.17 274.568 122.966 278.003 121.701C279.674 121.084 281.314 120.467 282.985 119.85C284.316 119.326 286.327 118.06 287.75 117.999C300.066 117.382 329.37 141.141 380.922 117.999C403.883 115.685 431.392 141.881 458.592 121.732C462.893 118.554 469.02 111.642 471 106.86C476.57 93.4375 474.775 75.4485 462.305 65.9758C456.333 61.4709 449.401 59.2801 441.82 59.7738C440.52 59.0024 441.263 40.9209 421.923 42.4328C415.796 25.6164 403.016 18.9824 388.38 31.2939C374.177 -3.23377 318.911 -12.3979 298.302 20.1549C298.148 20.3709 292.299 19.507 291.618 19.4761C289.174 19.4144 286.76 19.507 284.347 19.8155C279.581 20.4018 274.909 21.6977 270.515 23.6108C261.201 27.7146 254.3 34.2869 248.266 42.2786C247.926 42.7414 247.554 43.2042 247.09 43.5436C246.564 43.9139 245.976 44.0682 245.357 44.2533C239.942 45.9813 235.146 49.4062 231.587 53.7878C227.812 58.4779 226.265 64.0628 224.408 69.6477C217.198 68.1666 210.7 69.4625 205.78 75.0783C199.993 81.6814 198.199 89.9816 199.313 98.0659V98.1276Z" fill="#FEFFF2"/>
			</svg>
			<svg class="dm-header-cloud-right" alt="Cloud Asset" width="474" height="85" viewBox="0 0 474 85" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M-60.6873 98.1276C-59.2329 108.433 -53.0132 118.338 -44.1942 123.83C-35.2514 129.385 -24.5139 130.65 -14.2095 129.724C-6.81395 129.076 0.45787 127.348 7.57497 125.219C11.0716 124.17 14.5683 122.966 18.003 121.701C19.674 121.084 21.314 120.467 22.985 119.85C24.3156 119.326 26.327 118.06 27.7504 117.999C40.066 117.382 69.3699 141.141 120.922 117.999C143.883 115.685 171.392 141.881 198.592 121.732C202.893 118.554 209.02 111.642 211 106.86C216.57 93.4375 214.775 75.4485 202.305 65.9758C196.333 61.4709 189.401 59.2801 181.82 59.7738C180.52 59.0024 181.263 40.9209 161.923 42.4328C155.796 25.6164 143.016 18.9824 128.38 31.2939C114.177 -3.23377 58.9109 -12.3979 38.3022 20.1549C38.1475 20.3709 32.2991 19.507 31.6183 19.4761C29.1738 19.4144 26.7602 19.507 24.3465 19.8155C19.5812 20.4018 14.9086 21.6977 10.5146 23.6108C1.20052 27.7146 -5.69998 34.2869 -11.734 42.2786C-12.0744 42.7414 -12.4457 43.2042 -12.9099 43.5436C-13.4359 43.9139 -14.0239 44.0682 -14.6427 44.2533C-20.0579 45.9813 -24.8542 49.4062 -28.4128 53.7878C-32.188 58.4779 -33.7351 64.0628 -35.5918 69.6477C-42.8017 68.1666 -49.2999 69.4625 -54.22 75.0783C-60.0065 81.6814 -61.8012 89.9816 -60.6873 98.0659V98.1276Z" fill="#FEFFF2"/>
				<path d="M70.3127 98.1276C71.7671 108.433 77.9868 118.338 86.8058 123.83C95.7486 129.385 106.486 130.65 116.79 129.724C124.186 129.076 131.458 127.348 138.575 125.219C142.072 124.17 145.568 122.966 149.003 121.701C150.674 121.084 152.314 120.467 153.985 119.85C155.316 119.326 157.327 118.06 158.75 117.999C171.066 117.382 200.37 141.141 251.922 117.999C274.883 115.685 302.392 141.881 329.592 121.732C333.893 118.554 340.02 111.642 342 106.86C347.57 93.4375 345.775 75.4485 333.305 65.9758C327.333 61.4709 320.401 59.2801 312.82 59.7738C311.52 59.0024 312.263 40.9209 292.923 42.4328C286.796 25.6164 274.016 18.9824 259.38 31.2939C245.177 -3.23377 189.911 -12.3979 169.302 20.1549C169.148 20.3709 163.299 19.507 162.618 19.4761C160.174 19.4144 157.76 19.507 155.347 19.8155C150.581 20.4018 145.909 21.6977 141.515 23.6108C132.201 27.7146 125.3 34.2869 119.266 42.2786C118.926 42.7414 118.554 43.2042 118.09 43.5436C117.564 43.9139 116.976 44.0682 116.357 44.2533C110.942 45.9813 106.146 49.4062 102.587 53.7878C98.812 58.4779 97.2649 64.0628 95.4082 69.6477C88.1983 68.1666 81.7001 69.4625 76.78 75.0783C70.9935 81.6814 69.1988 89.9816 70.3127 98.0659V98.1276Z" fill="#FEFFF2"/>
				<path d="M199.313 98.1276C200.767 108.433 206.987 118.338 215.806 123.83C224.749 129.385 235.486 130.65 245.79 129.724C253.186 129.076 260.458 127.348 267.575 125.219C271.072 124.17 274.568 122.966 278.003 121.701C279.674 121.084 281.314 120.467 282.985 119.85C284.316 119.326 286.327 118.06 287.75 117.999C300.066 117.382 329.37 141.141 380.922 117.999C403.883 115.685 431.392 141.881 458.592 121.732C462.893 118.554 469.02 111.642 471 106.86C476.57 93.4375 474.775 75.4485 462.305 65.9758C456.333 61.4709 449.401 59.2801 441.82 59.7738C440.52 59.0024 441.263 40.9209 421.923 42.4328C415.796 25.6164 403.016 18.9824 388.38 31.2939C374.177 -3.23377 318.911 -12.3979 298.302 20.1549C298.148 20.3709 292.299 19.507 291.618 19.4761C289.174 19.4144 286.76 19.507 284.347 19.8155C279.581 20.4018 274.909 21.6977 270.515 23.6108C261.201 27.7146 254.3 34.2869 248.266 42.2786C247.926 42.7414 247.554 43.2042 247.09 43.5436C246.564 43.9139 245.976 44.0682 245.357 44.2533C239.942 45.9813 235.146 49.4062 231.587 53.7878C227.812 58.4779 226.265 64.0628 224.408 69.6477C217.198 68.1666 210.7 69.4625 205.78 75.0783C199.993 81.6814 198.199 89.9816 199.313 98.0659V98.1276Z" fill="#FEFFF2"/>
			</svg>
		</div>
		<div class="dm-header-titles">
			<svg class="dm-header-logo" alt="DarkMoon Logo Header" xmlns="http://www.w3.org/2000/svg" width="230" height="187" viewBox="0 0 244 187" fill="none">
				<path d="M243.56 119.568C243.67 125.708 243.32 131.738 242.56 137.648C240.1 156.518 224.1 171.058 205.08 170.538C200.52 170.408 196.28 169.428 192.57 167.248C165.55 151.318 156.42 185.288 121.79 186.698C87.16 185.288 78.03 151.318 51.01 167.248C47.3 169.438 43.06 170.418 38.5 170.538C19.44 171.068 3.46 156.468 1.01 137.558C0.34 132.398 0 127.138 0 121.798C0 52.9778 57.07 -2.51221 126.46 0.0877866C190.74 2.48779 242.41 55.2578 243.56 119.568Z" fill="var(--primary-color)"/>
				<path d="M171.379 116.918C171.379 113.938 166.229 111.308 157.759 109.248C158.329 109.818 158.559 110.388 158.559 110.968C158.559 112.568 156.379 114.168 152.719 115.428C148.259 117.028 141.619 118.178 133.719 118.748C137.269 117.258 140.699 114.968 143.679 112.108C149.519 106.268 152.609 98.5983 152.839 90.9283C151.919 92.2983 150.889 93.5583 149.749 94.8183C140.709 103.858 126.059 103.858 117.009 94.8183C107.969 85.7783 107.969 71.1283 117.009 62.0783C118.269 60.8183 119.529 59.7883 120.899 58.9883C113.229 59.2183 105.559 62.1983 99.7191 68.1483C87.5891 80.2783 87.5891 100.088 99.7191 112.218C102.699 115.198 106.019 117.368 109.679 118.858C101.549 118.168 94.7991 117.028 90.4491 115.428C86.7891 114.168 84.6091 112.568 84.6091 110.968C84.6091 110.398 84.9491 109.828 85.4091 109.248C76.9391 111.198 71.7891 113.938 71.7891 116.918C71.7891 120.468 79.3391 123.668 91.2491 125.728C91.4791 126.988 91.8191 128.128 92.2791 129.278C96.5191 141.298 108.079 149.998 121.579 149.998C135.079 149.998 146.649 141.298 150.999 129.278C151.459 128.128 151.799 126.988 152.029 125.728C163.819 123.668 171.379 120.468 171.379 116.918Z" fill="white"/>
				<path d="M123.301 87.497C126.211 87.497 128.561 84.577 128.561 80.977C128.561 77.377 126.201 74.457 123.301 74.457C120.401 74.457 118.031 77.377 118.031 80.977C118.031 84.577 120.391 87.497 123.301 87.497Z" fill="white"/>
				<path d="M143.1 87.497C146.01 87.497 148.37 84.577 148.37 80.977C148.37 77.377 146.01 74.457 143.1 74.457C140.19 74.457 137.84 77.377 137.84 80.977C137.84 84.577 140.2 87.497 143.1 87.497Z" fill="white"/>
			</svg>
			<svg class="dm-header-logo" alt="DARKMOON" xmlns="http://www.w3.org/2000/svg" width="513" height="65" viewBox="0 0 513 65" fill="none">
				<path d="M0 4.48797C0 2.83112 1.34315 1.48797 3 1.48797H22.088C28.8347 1.48797 34.7307 2.71997 39.776 5.18397C44.8213 7.64797 48.6933 11.1973 51.392 15.832C54.1493 20.408 55.528 25.776 55.528 31.936C55.528 42.144 52.536 49.888 46.552 55.168C40.568 60.3893 32.2667 63 21.648 63H3C1.34314 63 0 61.6568 0 60V4.48797ZM22.968 49.888C28.3067 49.888 32.384 48.2746 35.2 45.048C38.0747 41.8213 39.512 37.4506 39.512 31.936C39.512 26.8906 38.0747 22.7546 35.2 19.528C32.384 16.2426 28.4827 14.6 23.496 14.6H18.576C16.9191 14.6 15.576 15.9431 15.576 17.6V46.888C15.576 48.5448 16.9191 49.888 18.576 49.888H22.968Z" fill="white"/>
				<path d="M78.0704 3.37243C78.5264 2.23418 79.6292 1.48797 80.8553 1.48797H92.1046C93.3321 1.48797 94.4358 2.23579 94.8908 3.37586L117.048 58.8879C117.834 60.8582 116.383 63 114.262 63H104.814C103.576 63 102.466 62.2404 102.018 61.0873L98.5771 52.2406C98.1287 51.0875 97.0184 50.328 95.7811 50.328H77.1815C75.9443 50.328 74.8339 51.0875 74.3855 52.2406L70.9451 61.0873C70.4967 62.2404 69.3864 63 68.1491 63H58.6188C56.4957 63 55.0444 60.8552 55.8339 58.8844L78.0704 3.37243ZM89.686 38.36C91.7657 38.36 93.2145 36.2953 92.5071 34.3396L89.286 25.434C88.3332 22.7998 84.6102 22.7928 83.6476 25.4234L80.3885 34.329C79.6721 36.2865 81.1213 38.36 83.2058 38.36H89.686Z" fill="white"/>
				<path d="M124.867 4.48797C124.867 2.83112 126.21 1.48797 127.867 1.48797H152.499C159.715 1.48797 165.347 3.30664 169.395 6.94397C173.502 10.5813 175.555 15.8026 175.555 22.608C175.555 27.184 174.558 31.0853 172.563 34.312C171.116 36.6528 169.237 38.5614 166.925 40.0377C165.47 40.9672 164.775 42.8268 165.558 44.3664L172.812 58.6408C173.826 60.6366 172.376 63 170.137 63H160.854C159.724 63 158.689 62.3646 158.178 61.3563L150.076 45.3717C149.565 44.3634 148.531 43.728 147.4 43.728H143.443C141.786 43.728 140.443 45.0711 140.443 46.728V60C140.443 61.6568 139.1 63 137.443 63H127.867C126.21 63 124.867 61.6568 124.867 60V4.48797ZM149.771 30.616C152.881 30.616 155.286 30.0293 156.987 28.856C158.689 27.624 159.539 25.5413 159.539 22.608C159.539 19.5573 158.689 17.4746 156.987 16.36C155.345 15.1866 152.939 14.6 149.771 14.6H143.443C141.786 14.6 140.443 15.9431 140.443 17.6V27.616C140.443 29.2728 141.786 30.616 143.443 30.616H149.771Z" fill="white"/>
				<path d="M181.758 4.48797C181.758 2.83112 183.101 1.48797 184.758 1.48797H194.334C195.991 1.48797 197.334 2.83111 197.334 4.48797V18.7042C197.334 21.5121 200.846 22.7812 202.641 20.6216L217.642 2.57055C218.212 1.8847 219.058 1.48797 219.949 1.48797H230.622C233.164 1.48797 234.554 4.45289 232.927 6.40719L213.724 29.4785C212.794 30.5951 212.799 32.2172 213.734 33.329L234.546 58.0688C236.187 60.0201 234.8 63 232.25 63H221.61C220.725 63 219.885 62.6089 219.315 61.9315L202.629 42.1015C200.827 39.9599 197.334 41.2342 197.334 44.033V60C197.334 61.6568 195.991 63 194.334 63H184.758C183.101 63 181.758 61.6568 181.758 60V4.48797Z" fill="white"/>
				<path d="M244.836 4.48797C244.836 2.83112 246.179 1.48797 247.836 1.48797H257.885C259.051 1.48797 260.111 2.16324 260.604 3.21965L274.86 33.7786C275.936 36.0851 279.215 36.0882 280.295 33.7838L294.626 3.2145C295.12 2.16089 296.179 1.48797 297.343 1.48797H307.308C308.965 1.48797 310.308 2.83111 310.308 4.48797V60C310.308 61.6568 308.965 63 307.308 63H298.788C297.131 63 295.788 61.6568 295.788 60V45.4001C295.788 42.2025 291.453 41.2237 290.079 44.1112L281.906 61.2889C281.409 62.3341 280.355 63 279.197 63H275.952C274.792 63 273.736 62.3312 273.24 61.2826L265.156 44.1898C263.787 41.2957 259.444 42.2709 259.444 45.4724V60C259.444 61.6568 258.101 63 256.444 63H247.836C246.179 63 244.836 61.6568 244.836 60V4.48797Z" fill="white"/>
				<path d="M349.555 64.32C343.336 64.32 337.704 62.9706 332.659 60.272C327.672 57.5733 323.712 53.8186 320.779 49.008C317.904 44.1386 316.467 38.5653 316.467 32.288C316.467 26.0106 317.904 20.4373 320.779 15.568C323.712 10.6986 327.672 6.91464 332.659 4.21597C337.704 1.5173 343.336 0.167969 349.555 0.167969C355.715 0.167969 361.288 1.5173 366.275 4.21597C371.32 6.91464 375.28 10.6986 378.155 15.568C381.029 20.4373 382.467 26.0106 382.467 32.288C382.467 38.5653 381.029 44.1386 378.155 49.008C375.28 53.8186 371.32 57.5733 366.275 60.272C361.288 62.9706 355.715 64.32 349.555 64.32ZM349.555 49.712C352.664 49.712 355.509 49.008 358.091 47.6C360.672 46.192 362.725 44.168 364.251 41.528C365.776 38.888 366.539 35.808 366.539 32.288C366.539 28.768 365.776 25.7173 364.251 23.136C362.784 20.496 360.76 18.472 358.179 17.064C355.597 15.5973 352.723 14.864 349.555 14.864C346.328 14.864 343.424 15.5973 340.843 17.064C338.261 18.472 336.208 20.496 334.683 23.136C333.216 25.7173 332.483 28.768 332.483 32.288C332.483 35.808 333.216 38.888 334.683 41.528C336.208 44.168 338.261 46.192 340.843 47.6C343.483 49.008 346.387 49.712 349.555 49.712Z" fill="white"/>
				<path d="M419.508 64.32C413.289 64.32 407.657 62.9706 402.612 60.272C397.625 57.5733 393.665 53.8186 390.732 49.008C387.857 44.1386 386.42 38.5653 386.42 32.288C386.42 26.0106 387.857 20.4373 390.732 15.568C393.665 10.6986 397.625 6.91464 402.612 4.21597C407.657 1.5173 413.289 0.167969 419.508 0.167969C425.668 0.167969 431.241 1.5173 436.228 4.21597C441.273 6.91464 445.233 10.6986 448.108 15.568C450.982 20.4373 452.42 26.0106 452.42 32.288C452.42 38.5653 450.982 44.1386 448.108 49.008C445.233 53.8186 441.273 57.5733 436.228 60.272C431.241 62.9706 425.668 64.32 419.508 64.32ZM419.508 49.712C422.617 49.712 425.462 49.008 428.044 47.6C430.625 46.192 432.678 44.168 434.204 41.528C435.729 38.888 436.492 35.808 436.492 32.288C436.492 28.768 435.729 25.7173 434.204 23.136C432.737 20.496 430.713 18.472 428.132 17.064C425.55 15.5973 422.676 14.864 419.508 14.864C416.281 14.864 413.377 15.5973 410.796 17.064C408.214 18.472 406.161 20.496 404.636 23.136C403.169 25.7173 402.436 28.768 402.436 32.288C402.436 35.808 403.169 38.888 404.636 41.528C406.161 44.168 408.214 46.192 410.796 47.6C413.436 49.008 416.34 49.712 419.508 49.712Z" fill="white"/>
				<path d="M458.562 4.48797C458.562 2.83112 459.906 1.48797 461.562 1.48797H472.29C473.329 1.48797 474.295 2.02652 474.842 2.91123L491.026 29.1087C492.615 31.6798 496.578 30.5542 496.578 27.532V4.48797C496.578 2.83111 497.922 1.48797 499.578 1.48797H509.154C510.811 1.48797 512.154 2.83111 512.154 4.48797V60C512.154 61.6568 510.811 63 509.154 63H498.514C497.475 63 496.51 62.4622 495.963 61.5784L479.69 35.2762C478.1 32.707 474.138 33.8335 474.138 36.8547V60C474.138 61.6568 472.795 63 471.138 63H461.562C459.906 63 458.562 61.6568 458.562 60V4.48797Z" fill="white"/>
			</svg>
			<p class="dm-header-text">Enhance your security audits with DarkMoon. A Cygwin-powered pentesting arsenal</p>
			<DmButton
				title="Download"
				:logo="DiscoverSVG"
				alt="Discover SVG Logo"
				link="https://www.microsoft.com/store/apps/9N2TN688BBXT"
			></DmButton>
		</div>
	</header>
</template>

<script>
	import { loadSlim } from "tsparticles-slim";

	import DmButton from '../_commons/components/DmButton.vue';

	export default {
		name: 'HeaderComponent',
		components: {
			DmButton
		},
		data() {
			return {
				DiscoverSVG: require('../assets/icons/navigation-button-logo.svg'),
				particlesInit: async (engine) => await loadSlim(engine)
			}
		}
	}
</script>

<style lang="scss">
	.dm-header {

		background-color: var(--aside-bg);
		border-radius: var(--aside-radius);
		margin-top: 10rem;
		overflow: hidden;
		position: relative;
		z-index: 0;

		.dm-header-titles {

			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 2rem;
			text-align: center;

			.dm-header-logo { padding-bottom: 1rem; }

			.dm-header-text {

				font-size: 1.2rem !important;
				max-width: 27rem;
				padding: 1rem 0 2rem 0;

			}
		}

		.dm-header-assets {

			$animation-duration: 10s;
			$planet-margin: 3.31rem;

			height: 100%;
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: -1;

			.dm-header-planet-left,
			.dm-header-planet-right {

				animation: rotationRight $animation-duration infinite linear alternate;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;

			}

			.dm-header-planet-left {

				animation: rotationLeft $animation-duration infinite linear alternate;
				left: $planet-margin;
				transform: translateY(-50%) scaleX(-1);

			}

			.dm-header-planet-right { right: $planet-margin; }

			.dm-header-cloud-left,
			.dm-header-cloud-right {

				position: absolute;
				bottom: 0;

			}

			.dm-header-cloud-left { left: 0; }

			.dm-header-cloud-right {

				right: 0;
				transform: scaleX(-1);

			}
		}
	}

	@media (max-width: 1000px) {
		.dm-header {
			.dm-header-titles {
				.dm-header-logo {

					max-height: 7.5rem;
					max-width: 100%;

				}
			}

			.dm-header-assets {

				$origin: -15rem;

				.dm-header-planet-left,
				.dm-header-planet-right { display: none; }
				.dm-header-cloud-left { left: $origin; }
				.dm-header-cloud-right { right: $origin; }
			}
		}
	}

	@media (max-width: 700px) {
		.dm-header { margin-top: 6rem; }
	}

	@media (max-width: 500px) {
		.dm-header {
			.dm-header-titles .dm-header-text { font-size: 1rem !important; }

			.dm-header-assets {

				$origin: -95%;

				.dm-header-cloud-left { left: $origin; }
				.dm-header-cloud-right { right: $origin; }
			}
		}
	}

	@keyframes rotationLeft {
		0% { transform: translateY(-50%) scaleX(-1) rotateZ(0deg); }
		100% { transform: translateY(-50%) scaleX(-1) rotateZ(-90deg); }
	}

	@keyframes rotationRight {
		0% { transform: translateY(-50%) rotateZ(0deg); }
		100% { transform: translateY(-50%) rotateZ(-90deg); }
	}
</style>