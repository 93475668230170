<template>
	<article class="dm-opensource">
		<h2 class="dm-section-title">open source</h2>
		<div class="dm-other-opensource-container">
			<div class="dm-other-opensource-left">
				<h3 class="dm-other-opensource-title">GNU software</h3>
				<p class="dm-other-opensource-text">Dark Moon is a large collection of GNU tools ported to Windows and managed by the Cygwin DLL.</p>
				<div class="dm-other-opensource-button">
					<DmButton
						title="Download"
						:logo="DiscoverSVG"
						alt="Download More"
						link="https://www.microsoft.com/store/apps/9N2TN688BBXT"
					></DmButton>
				</div>
			</div>
			<div class="dm-other-opensource-right">
				<svg class="dm-other-opensource-picture" alt="Darkmoon Open Source SVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 388 283.58">
					<g id="DESIGNED_BY_FREEPIK" data-name="DESIGNED BY FREEPIK">
						<g>
							<g>
								<path class="cls-3" d="M363.66,11.56l-7.16,17.34-17.18,7.22,17.18,7.22,7.16,17.34,7.16-17.34,17.18-7.22-17.18-7.22-7.16-17.34ZM331.08,19.87l3.94,11.92,3.94-11.92,11.81-3.97-11.81-3.97-3.94-11.92-3.94,11.92-11.81,3.97,11.81,3.97ZM337.53,50.93l-2.51-7.59-2.51,7.59-7.52,2.53,7.52,2.53,2.51,7.59,2.51-7.59,7.52-2.53-7.52-2.53Z"/>
								<path class="cls-3" d="M44.39,231.56l-7.16,17.34-17.18,7.22,17.18,7.22,7.16,17.34,7.16-17.34,17.18-7.22-17.18-7.22-7.16-17.34ZM11.81,239.87l3.94,11.92,3.94-11.92,11.81-3.97-11.81-3.97-3.94-11.92-3.94,11.92-11.81,3.97,11.81,3.97ZM18.26,270.93l-2.51-7.59-2.51,7.59-7.52,2.53,7.52,2.53,2.51,7.59,2.51-7.59,7.52-2.53-7.52-2.53Z"/>
							</g>
							<g>
								<g>
									<circle class="cls-2" cx="194" cy="126.21" r="107.23"/>
									<g>
										<path class="cls-3" d="M237.68,118.73c0-2.61-4.52-4.92-11.95-6.73.5.5.7,1,.7,1.51,0,1.41-1.91,2.81-5.12,3.92-3.92,1.41-9.74,2.41-16.67,2.91,3.11-1.31,6.12-3.31,8.74-5.82,5.12-5.12,7.83-11.85,8.03-18.58-.8,1.21-1.71,2.31-2.71,3.41-7.93,7.93-20.79,7.93-28.72,0-7.93-7.93-7.93-20.79,0-28.72,1.1-1.1,2.21-2.01,3.41-2.71-6.73.2-13.46,2.81-18.58,8.03-10.64,10.64-10.64,28.02,0,38.66,2.61,2.61,5.52,4.52,8.74,5.82-7.13-.6-13.05-1.61-16.87-3.01-3.21-1.11-5.12-2.51-5.12-3.92,0-.5.3-1,.7-1.51-7.43,1.71-11.95,4.12-11.95,6.73,0,3.11,6.63,5.92,17.07,7.73.2,1.1.5,2.11.9,3.11,3.71,10.54,13.86,18.18,25.71,18.18s21.99-7.63,25.81-18.18c.4-1.01.7-2.01.9-3.11,10.34-1.81,16.97-4.62,16.97-7.73Z"/>
										<path class="cls-3" d="M195.51,92.92c2.55,0,4.62-2.56,4.62-5.72s-2.07-5.72-4.62-5.72-4.62,2.56-4.62,5.72,2.07,5.72,4.62,5.72Z"/>
										<path class="cls-3" d="M212.88,92.92c2.55,0,4.62-2.56,4.62-5.72s-2.07-5.72-4.62-5.72-4.62,2.56-4.62,5.72,2.07,5.72,4.62,5.72Z"/>
									</g>
								</g>
								<path class="cls-1" d="M256.06,172.97c-16.7-14.32-29.18.04-43.92,6.59-5.24,1.24-11.09,2.32-18.14,2.72-7.05-.4-12.9-1.48-18.14-2.72-14.74-6.56-27.22-20.91-43.92-6.59-21.06,18.06-64.32-27.42-64.32,5.92,0,47.33,56.58,85.7,126.38,85.7s126.38-38.37,126.38-85.7c0-33.34-43.25,12.14-64.32-5.92Z"/>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
	</article>
</template>

<script>
	import DmButton from '../_commons/components/DmButton.vue';

	export default {
		name: 'OpenSourceComponent',
		components: {
			DmButton
		},
		data() {
			return {
				DiscoverSVG: require('../assets/icons/navigation-button-logo.svg')
			}
		}
	}
</script>

<style lang="scss">
	.dm-opensource {

		display: flex;
		flex-direction: column;
		justify-content: center;

		.dm-other-opensource-container {

			background-color: var(--aside-bg);
			border-radius: var(--aside-radius);
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			padding: 2rem;

			.dm-other-opensource-left,
			.dm-other-opensource-right {

				display: block;
				width: 50%;

			}

			.dm-other-opensource-left {

				position: relative;

				.dm-other-opensource-title {

					font-size: 1.75rem;
					padding-bottom: 2rem;

				}

				.dm-other-opensource-text {

					max-width: 397px;
					text-align: justify;

				}

				.dm-other-opensource-button {

					bottom: 0;
					position: absolute;

				}
			}

			.dm-other-opensource-right {

				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				margin: 1rem 0;

				.dm-other-opensource-picture {

					max-height: 283.58px;
					max-width: 388px;

					.cls-1 { fill: #fefff2; }
					.cls-1, .cls-2, .cls-3 { stroke-width: 0px; }
					.cls-2 { fill: var(--primary-color); }
					.cls-3 { fill: #fff; }
				}
			}
		}
	}

	@media (max-width: 1000px) {
		.dm-opensource .dm-other-opensource-container {

			flex-direction: column;
			padding: 2rem 1rem 1rem 1rem;

			.dm-other-opensource-left,
			.dm-other-opensource-right { width: 100%; }

			.dm-other-opensource-left {
				
				margin-bottom: 1rem;
				padding-bottom: 7rem;

				.dm-other-opensource-text { max-width: 410px; }
			}

			.dm-other-opensource-right {

				background-color: var(--main-bg);
				border-radius: var(--aside-radius);
				margin: 2rem 0 0 0;
				padding: 2rem 0;

				.dm-other-opensource-picture { margin: 0 1rem; }
			}
		}
	}
</style>