<template>
	<button class="dm-social-button" @click="redirect(link)">{{ title }}</button>
</template>

<script>
	export default {
		name: 'DmSocialButtonComponent',
		props: {
			title: String,
			link: String
		},
		methods: {
			redirect(url) { window.open(url, '_parent') }
		}
	}
</script>

<style lang="scss">
	.dm-social-button {

		background-color: var(--aside-bg);
		border: none;
		border-radius: var(--aside-radius);
		color: var(--main-color);
		cursor: pointer;
		font-family: Prompt;
		font-weight: bold;
		height: 3.5625rem;
		text-transform: uppercase;
		width: 7.625rem;

	}
</style>