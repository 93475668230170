<template>
	<article class="dm-other-features">
		<h2 class="dm-section-title">features</h2>
		<div class="dm-other-features-container">
			<div class="dm-other-features-item" v-for="(feature, index) in features" :key="index">
				<h3 class="dm-other-features-title">{{ feature.h3 }}</h3>
				<p class="dm-other-features-text">{{ feature.p }}</p>
			</div>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'OtherFeaturesComponent',
		data() {
			return {
				features: [
					{
						h3: "Ultimate arsenal",
						p: "Leverage Cygwin for servers and microservices with Dark Moon Cygwin, enabling Apache, MySQL, Python..."
					}, {
						h3: "Server & Microservices",
						p: "Integrate Linux-like server with Dark Moon for optimized cross-platform development and microservices."
					}, {
						h3: "Pentests tools",
						p: "Dark Moon: Your all-in-one network security and development solution. Dive into GCC, Nmap, SQLmap, and more on Windows."
					}, {
						h3: "Security & Development",
						p: "Boost Windows with Linux's leading network and security tools, covering OSINT, network mapping, and forensics."
					}
				]
			}
		}
	}
</script>

<style lang="scss">
	.dm-other-features {

		display: flex;
		flex-direction: column;
		justify-content: center;

		.dm-other-features-container {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: -.75rem;

			.dm-other-features-item {

				background-color: var(--third-color);
				border-radius: var(--aside-radius);
				display: flex;
				flex-direction: column;
				padding: 2rem;
				margin: .75rem;
				width: calc(50% - 5.5rem);

				.dm-other-features-title {
					
					font-size: 1.75rem;
					margin-bottom: .5rem;

				}

				.dm-other-features-text {

					text-align: justify;

				}
			}
		}
	}

	@media (max-width: 1000px) {
		.dm-other-features {
			.dm-other-features-container {

				flex-direction: column;
				margin: -.5rem;

				.dm-other-features-item {

					margin: .5rem;
					padding: 2rem 1rem;
					width: calc(100% - 3rem);

				}
			}
		}
	}
</style>