<template>
	<button class="dm-button" @click="redirect(link)">
		<span class="dm-button-text">{{ title }}</span>
		<img class="dm-button-picture" v-if="logo" :src="logo" :alt="alt" :draggable="false">
	</button>
</template>

<script>
	export default {
		name: 'DmButtonComponent',
		props: {
			alt: String,
			link: String,
			logo: String,
			title: String,
		},
		methods: {
			redirect(url) { window.open(url, '_parent') }
		}
	}
</script>

<style lang="scss">
	.dm-button {

		$radius: 16px;

		align-items: center;
		background-color: var(--primary-color);
		border: none;
		border-radius: $radius;
		color: var(--main-color);
		cursor: pointer;
		display: flex;
		flex-direction: row;
		font-size: 1.1rem;
		font-weight: bolder;
		justify-content: center;
		overflow: hidden;
		padding: 1.2em 1.5em;
		position: relative;
		transition: .5s all;
		z-index: 0;

		.dm-button-picture { padding-left: 1rem; }

		&:before {

			background-color: #F25E5E;
			border-radius: 0 50px 50px 0;
			content: '';
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			z-index: -1;
			transition: .4s all;
			width: 0;

		}

		&:hover {

			border-radius: calc($radius + 8px);

			.dm-button-picture,
			.dm-button-text { animation: fontFade .5s forwards running; }

			&:before { width: calc(100% - 4rem); }
		}

		.active, &:active {
			&:before { width: calc(100% + 50px); }
		}
	}

	@keyframes fontFade {

		0%, 100% { opacity: 1; }
		50% { opacity: .75; }

	}
</style>