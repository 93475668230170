<template>
	<article class="dm-partners">
		<h2 class="dm-section-title">they believe in us</h2>
		<div class="dm-partners-container">
			<Carousel
				:items-to-show="this.clientWidth < 1000 ? (this.clientWidth < 700 ? 1.15 : 2.15) : 3.2"
				:wrap-around="true"
				:spacePadding="8"
			>
				<Slide v-for="(partner, index) in partners" :key="index">
					<div class="dm-partners-item carousel__item">
						<img class="dm-partner-picture" :src="partner.img" :alt="partner.alt">
						<p class="dm-partner-paragraphe">{{ partner.txt }}</p>
					</div>
				</Slide>
				<template #addons>
					<div class="dm-partners-pagination">
						<Pagination />
					</div>
				</template>
			</Carousel>
		</div>
	</article>
</template>

<script>
	import 'vue3-carousel/dist/carousel.css';
	import { Carousel, Pagination, Slide } from 'vue3-carousel';

	export default {
		name: 'PartnersComponent',
		components: {
			Carousel,
			Pagination,
			Slide
		},
		mounted() { window.addEventListener("resize", this.onResize, true) },
		beforeUnmount() { window.removeEventListener("resize", this.onResize, true) },
		methods: {
			onResize() { this.clientWidth = window.innerWidth; }
		},
		data() {
			return {
				partners: [
					{
						img: require('../assets/icons/ibm.svg'),
						alt: "IBM Logo Partner",
						txt: "Dark Moon has been a member of the IBM Partner network since September 2020"
					}, {
						img: require('../assets/icons/advanced-installer.svg'),
						alt: "Advanced Installer Logo Partner",
						txt: "Software license distribution as part of an Open Source partnership"
					}, {
						img: require('../assets/icons/microsoft.svg'),
						alt: "Microsoft Logo Partner",
						txt: "UWP development with the support of Desktop Bridge engineers"
					}, {
						img: require('../assets/icons/1and1.svg'),
						alt: "One & One Logo Partner",
						txt: "Dark Moon has been a member of the 1&1 ionos partner network for 3 years"
					}
				],
				clientWidth: window.innerWidth
			}
		}
	}
</script>

<style lang="scss">
	.dm-partners {

		display: flex;
		flex-direction: column;
		justify-content: center;

		.dm-partners-container {

			.is-hover { cursor: grab; }
			.is-dragging { cursor: grabbing; }

			.dm-partners-item {

				background-color: var(--aside-bg);
				border-radius: var(--aside-radius);
				height: 19.0625rem;
				margin: 0 .5rem;
				max-width: 22.0625rem;
				overflow: hidden;
				padding: 0 1.5rem;
				width: calc(31vw - 1rem);
				text-align: left;

				.dm-partner-picture {

					height: 60.27px;
					max-width: 100%;
					margin-top: 3rem;

				}

				.dm-partner-paragraphe { margin: 2rem 0; }
			}

			.dm-partners-pagination {

				bottom: -3rem;
				display: none;
				left: 50%;
				position: absolute;
				transform: translateX(-50%);

				button.carousel__pagination-button::after {

					$size: 1.5rem;

					background-color: rgb(65, 65, 65);
					border-radius: 50px;
					height: $size;
					width: $size;

				}

				.carousel__pagination-button--active::after { background-color: #fff !important; }
			}
		}
	}

	@media (max-width: 1000px) {
		.dm-partners .dm-partners-container {

			margin: 0 -1rem;
			width: calc(100% + 2rem);

			.dm-partners-item {

				height: 17.375rem !important;
				min-width: 17.375rem;
				max-width: none !important;
				padding: 0 1rem !important;
				width: calc(85vw) !important;
				margin: 0 .5rem;

				.dm-partner-picture { height: 2.625rem; }
			}

			.dm-partners-pagination { display: block !important; }
			.dm-partner-paragraphe { margin: 1rem 0 !important; }
		}
	}
</style>