<template>
	<div class="dm-main">
		<sidebar class="dm-sidenav">
			<NavBar></NavBar>
		</sidebar>
		<aside class="dm-content">
			<Header></Header>
			<Partners></Partners>
			<Shell></Shell>
			<Features></Features>
			<OpenSource></OpenSource>
			<OtherFeatures></OtherFeatures>
			<Footer></Footer>
		</aside>
	</div>
</template>

<script>
	import Features from './components/Features.vue'
	import Footer from './components/Footer.vue';
	import Header from './components/Header.vue';
	import NavBar from './components/NavBar.vue';
	import OpenSource from './components/OpenSource.vue'
	import OtherFeatures from './components/OtherFeatures.vue';
	import Partners from './components/Partners.vue';
	import Shell from './components/Shell.vue';

	export default {
		name: 'App',
		components: {
			Features,
			Header,
			NavBar,
			OpenSource,
			OtherFeatures,
			Partners,
			Shell,
			Footer
		}
	}
</script>

<style lang="scss">
	.dm-main {

		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 100%;

		.dm-sidenav {}

		.dm-content {

			display: flex;
			flex-direction: column;
			max-width: 1200px;
			width: 100%;

		}
	}
</style>
