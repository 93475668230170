<template>
	<article class="dm-features">
		<h2 class="dm-section-title">your ultimate choice</h2>
		<div class="dm-features-container">
			<div class="dm-features-item" v-for="(feature, index) in features" :key="index">
				<h3 class="dm-features-title">{{ feature.h3 }}</h3>
				<p class="dm-features-text">{{ feature.p }}</p>
				<div class="dm-other-opensource-button">
					<DmButton
						title="Learn More"
						:logo="LearnMoreSVG"
						alt="Learn More SVG Logo"
						:link="feature.url"
					></DmButton>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
	import DmButton from '@/_commons/components/DmButton.vue';

	export default {
		name: 'FeaturesComponent',
		components: {
			DmButton
		},
		data() {
			return {
				LearnMoreSVG: require('../assets/icons/learn-more.svg'),
				features: [
					{
						h3: "Cygwin portable",
						p: "Immerse yourself in the realm of unparalleled cybersecurity through Dark Moon's Cygwin Portable fusion, boasting an extensive array of cutting-edge pentesting tools for a truly comprehensive experience.",
						url: "https://johntheripper.developpez.com/darkmoon-project/"
					}, {
						h3: "Pentest tools",
						p: "Explore an extensive range of top-tier pentest tools, spanning framework, OSINT, web mapping, vulnerability scanning, network monitoring, intrusion, brute force, and forensics.",
						url: "https://linuxfr.org/news/dark-moon-x-un-sous-systeme-cygwin-portable-xfce-sans-wsl"
					}, {
						h3: "Unix Portable",
						p: "You can view files in the UNIX tree from the file manager to make changes, and you can even move files to a USB stick.",
						url: "https://dark-moon-x.github.io/DarkMoon/"
					}, {
						h3: "Graphics office XFCE",
						p: "Since 2019, the new version of Dark Moon integrates CygwinX, it is possible to launch an entire Xfce 4 and use graphical tools, but also command line tools such as git, gcc, midnight commander...",
						url: "https://dark-moon-x.github.io/DarkMoon/"
					},
				]
			}
		}
	}
</script>

<style lang="scss">
	.dm-features {

		display: flex;
		flex-direction: column;
		justify-content: center;

		.dm-features-container {

			$margin: .75rem;

			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin: -$margin;

			.dm-features-item {

				background-color: var(--aside-bg);
				border-radius: var(--aside-radius);
				margin: $margin;
				min-height: 20rem;
				padding: 2rem;
				position: relative;

				&:nth-of-type(1), &:nth-of-type(4) { width: calc(60% - (4rem + $margin * 2)); }
				&:nth-of-type(2), &:nth-of-type(3) { width: calc(40% - (4rem + $margin * 2)); }

				.dm-features-title {

					font-size: 1.75rem;
					padding-bottom: 2rem;

				}

				.dm-features-text { text-align: justify; }

				.dm-other-opensource-button {

					position: absolute;
					bottom: 2rem;

				}
			}
		}
	}

	@media (max-width: 1000px) {
		.dm-features .dm-features-container {

			$margin: .5rem;

			margin: -$margin;

			.dm-features-item {

				margin: $margin !important;
				min-height: auto !important;
				padding: 2rem 1rem 8rem 1rem !important;
				width: 100% !important;

				.dm-other-opensource-button { bottom: 1rem !important; }
			}
		}
	}
</style>