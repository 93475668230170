<template>
	<nav class="dm-nav" :class="this.currentScroll > 0 ? 'edged' : ''">
		<div class="dm-nav-mobile">
			<div class="dm-nav-burger" @click="this.toggleMenu()">
				<MenuIcon v-if="!this.isMenuOpen" size="2.5rem"></MenuIcon>
				<CloseIcon v-if="this.isMenuOpen" size="2.5rem"></CloseIcon>
			</div>
			<button class="dm-nav-hero" title="DARKMOON" @click="this.scrollToTop()">
				<svg alt="Darkmoon Logo" xmlns="http://www.w3.org/2000/svg" width="76" height="69" viewBox="0 0 76 69" fill="none">
					<path d="M75.1855 43.7343C75.1855 41.4845 71.2975 39.499 64.9031 37.9438C65.3334 38.3741 65.507 38.8044 65.507 39.2423C65.507 40.4502 63.8612 41.6581 61.0981 42.6094C57.731 43.8173 52.7182 44.6855 46.7541 45.1158C49.4341 43.9909 52.0236 42.2621 54.2734 40.1029C58.6823 35.694 61.0151 29.9036 61.1887 24.1131C60.4941 25.1474 59.7165 26.0986 58.8559 27.0499C52.0312 33.8746 40.9711 33.8746 34.1388 27.0499C27.3141 20.2251 27.3141 9.16509 34.1388 2.3328C35.0901 1.38156 36.0413 0.60396 37.0756 0C31.2851 0.173639 25.4947 2.42339 21.0858 6.91534C11.9282 16.0729 11.9282 31.0284 21.0858 40.186C23.3355 42.4357 25.8419 44.074 28.6051 45.1989C22.4673 44.6779 17.3714 43.8173 14.0874 42.6094C11.3242 41.6581 9.67846 40.4502 9.67846 39.2423C9.67846 38.812 9.93514 38.3817 10.2824 37.9438C3.88799 39.4159 0 41.4845 0 43.7343C0 46.4143 5.69987 48.8302 14.6913 50.3854C14.865 51.3366 15.1216 52.1972 15.4689 53.0654C18.6699 62.1399 27.3971 68.708 37.589 68.708C47.7808 68.708 56.5156 62.1399 59.7996 53.0654C60.1469 52.1972 60.4036 51.3366 60.5772 50.3854C69.4781 48.8302 75.1855 46.4143 75.1855 43.7343Z" fill="white"/>
					<path d="M38.8887 21.5242C41.0857 21.5242 42.8598 19.3198 42.8598 16.602C42.8598 13.8841 41.0781 11.6797 38.8887 11.6797C36.6994 11.6797 34.9102 13.8841 34.9102 16.602C34.9102 19.3198 36.6918 21.5242 38.8887 21.5242Z" fill="white"/>
					<path d="M53.8343 21.5242C56.0312 21.5242 57.8129 19.3198 57.8129 16.602C57.8129 13.8841 56.0312 11.6797 53.8343 11.6797C51.6374 11.6797 49.8633 13.8841 49.8633 16.602C49.8633 19.3198 51.645 21.5242 53.8343 21.5242Z" fill="white"/>
				</svg>
				<svg alt="DARKMOON" xmlns="http://www.w3.org/2000/svg" width="513" height="65" viewBox="0 0 513 65" fill="none">
					<path d="M0 4.48797C0 2.83112 1.34315 1.48797 3 1.48797H22.088C28.8347 1.48797 34.7307 2.71997 39.776 5.18397C44.8213 7.64797 48.6933 11.1973 51.392 15.832C54.1493 20.408 55.528 25.776 55.528 31.936C55.528 42.144 52.536 49.888 46.552 55.168C40.568 60.3893 32.2667 63 21.648 63H3C1.34314 63 0 61.6568 0 60V4.48797ZM22.968 49.888C28.3067 49.888 32.384 48.2746 35.2 45.048C38.0747 41.8213 39.512 37.4506 39.512 31.936C39.512 26.8906 38.0747 22.7546 35.2 19.528C32.384 16.2426 28.4827 14.6 23.496 14.6H18.576C16.9191 14.6 15.576 15.9431 15.576 17.6V46.888C15.576 48.5448 16.9191 49.888 18.576 49.888H22.968Z" fill="white"/>
					<path d="M78.0704 3.37243C78.5264 2.23418 79.6292 1.48797 80.8553 1.48797H92.1046C93.3321 1.48797 94.4358 2.23579 94.8908 3.37586L117.048 58.8879C117.834 60.8582 116.383 63 114.262 63H104.814C103.576 63 102.466 62.2404 102.018 61.0873L98.5771 52.2406C98.1287 51.0875 97.0184 50.328 95.7811 50.328H77.1815C75.9443 50.328 74.8339 51.0875 74.3855 52.2406L70.9451 61.0873C70.4967 62.2404 69.3864 63 68.1491 63H58.6188C56.4957 63 55.0444 60.8552 55.8339 58.8844L78.0704 3.37243ZM89.686 38.36C91.7657 38.36 93.2145 36.2953 92.5071 34.3396L89.286 25.434C88.3332 22.7998 84.6102 22.7928 83.6476 25.4234L80.3885 34.329C79.6721 36.2865 81.1213 38.36 83.2058 38.36H89.686Z" fill="white"/>
					<path d="M124.867 4.48797C124.867 2.83112 126.21 1.48797 127.867 1.48797H152.499C159.715 1.48797 165.347 3.30664 169.395 6.94397C173.502 10.5813 175.555 15.8026 175.555 22.608C175.555 27.184 174.558 31.0853 172.563 34.312C171.116 36.6528 169.237 38.5614 166.925 40.0377C165.47 40.9672 164.775 42.8268 165.558 44.3664L172.812 58.6408C173.826 60.6366 172.376 63 170.137 63H160.854C159.724 63 158.689 62.3646 158.178 61.3563L150.076 45.3717C149.565 44.3634 148.531 43.728 147.4 43.728H143.443C141.786 43.728 140.443 45.0711 140.443 46.728V60C140.443 61.6568 139.1 63 137.443 63H127.867C126.21 63 124.867 61.6568 124.867 60V4.48797ZM149.771 30.616C152.881 30.616 155.286 30.0293 156.987 28.856C158.689 27.624 159.539 25.5413 159.539 22.608C159.539 19.5573 158.689 17.4746 156.987 16.36C155.345 15.1866 152.939 14.6 149.771 14.6H143.443C141.786 14.6 140.443 15.9431 140.443 17.6V27.616C140.443 29.2728 141.786 30.616 143.443 30.616H149.771Z" fill="white"/>
					<path d="M181.758 4.48797C181.758 2.83112 183.101 1.48797 184.758 1.48797H194.334C195.991 1.48797 197.334 2.83111 197.334 4.48797V18.7042C197.334 21.5121 200.846 22.7812 202.641 20.6216L217.642 2.57055C218.212 1.8847 219.058 1.48797 219.949 1.48797H230.622C233.164 1.48797 234.554 4.45289 232.927 6.40719L213.724 29.4785C212.794 30.5951 212.799 32.2172 213.734 33.329L234.546 58.0688C236.187 60.0201 234.8 63 232.25 63H221.61C220.725 63 219.885 62.6089 219.315 61.9315L202.629 42.1015C200.827 39.9599 197.334 41.2342 197.334 44.033V60C197.334 61.6568 195.991 63 194.334 63H184.758C183.101 63 181.758 61.6568 181.758 60V4.48797Z" fill="white"/>
					<path d="M244.836 4.48797C244.836 2.83112 246.179 1.48797 247.836 1.48797H257.885C259.051 1.48797 260.111 2.16324 260.604 3.21965L274.86 33.7786C275.936 36.0851 279.215 36.0882 280.295 33.7838L294.626 3.2145C295.12 2.16089 296.179 1.48797 297.343 1.48797H307.308C308.965 1.48797 310.308 2.83111 310.308 4.48797V60C310.308 61.6568 308.965 63 307.308 63H298.788C297.131 63 295.788 61.6568 295.788 60V45.4001C295.788 42.2025 291.453 41.2237 290.079 44.1112L281.906 61.2889C281.409 62.3341 280.355 63 279.197 63H275.952C274.792 63 273.736 62.3312 273.24 61.2826L265.156 44.1898C263.787 41.2957 259.444 42.2709 259.444 45.4724V60C259.444 61.6568 258.101 63 256.444 63H247.836C246.179 63 244.836 61.6568 244.836 60V4.48797Z" fill="white"/>
					<path d="M349.555 64.32C343.336 64.32 337.704 62.9706 332.659 60.272C327.672 57.5733 323.712 53.8186 320.779 49.008C317.904 44.1386 316.467 38.5653 316.467 32.288C316.467 26.0106 317.904 20.4373 320.779 15.568C323.712 10.6986 327.672 6.91464 332.659 4.21597C337.704 1.5173 343.336 0.167969 349.555 0.167969C355.715 0.167969 361.288 1.5173 366.275 4.21597C371.32 6.91464 375.28 10.6986 378.155 15.568C381.029 20.4373 382.467 26.0106 382.467 32.288C382.467 38.5653 381.029 44.1386 378.155 49.008C375.28 53.8186 371.32 57.5733 366.275 60.272C361.288 62.9706 355.715 64.32 349.555 64.32ZM349.555 49.712C352.664 49.712 355.509 49.008 358.091 47.6C360.672 46.192 362.725 44.168 364.251 41.528C365.776 38.888 366.539 35.808 366.539 32.288C366.539 28.768 365.776 25.7173 364.251 23.136C362.784 20.496 360.76 18.472 358.179 17.064C355.597 15.5973 352.723 14.864 349.555 14.864C346.328 14.864 343.424 15.5973 340.843 17.064C338.261 18.472 336.208 20.496 334.683 23.136C333.216 25.7173 332.483 28.768 332.483 32.288C332.483 35.808 333.216 38.888 334.683 41.528C336.208 44.168 338.261 46.192 340.843 47.6C343.483 49.008 346.387 49.712 349.555 49.712Z" fill="white"/>
					<path d="M419.508 64.32C413.289 64.32 407.657 62.9706 402.612 60.272C397.625 57.5733 393.665 53.8186 390.732 49.008C387.857 44.1386 386.42 38.5653 386.42 32.288C386.42 26.0106 387.857 20.4373 390.732 15.568C393.665 10.6986 397.625 6.91464 402.612 4.21597C407.657 1.5173 413.289 0.167969 419.508 0.167969C425.668 0.167969 431.241 1.5173 436.228 4.21597C441.273 6.91464 445.233 10.6986 448.108 15.568C450.982 20.4373 452.42 26.0106 452.42 32.288C452.42 38.5653 450.982 44.1386 448.108 49.008C445.233 53.8186 441.273 57.5733 436.228 60.272C431.241 62.9706 425.668 64.32 419.508 64.32ZM419.508 49.712C422.617 49.712 425.462 49.008 428.044 47.6C430.625 46.192 432.678 44.168 434.204 41.528C435.729 38.888 436.492 35.808 436.492 32.288C436.492 28.768 435.729 25.7173 434.204 23.136C432.737 20.496 430.713 18.472 428.132 17.064C425.55 15.5973 422.676 14.864 419.508 14.864C416.281 14.864 413.377 15.5973 410.796 17.064C408.214 18.472 406.161 20.496 404.636 23.136C403.169 25.7173 402.436 28.768 402.436 32.288C402.436 35.808 403.169 38.888 404.636 41.528C406.161 44.168 408.214 46.192 410.796 47.6C413.436 49.008 416.34 49.712 419.508 49.712Z" fill="white"/>
					<path d="M458.562 4.48797C458.562 2.83112 459.906 1.48797 461.562 1.48797H472.29C473.329 1.48797 474.295 2.02652 474.842 2.91123L491.026 29.1087C492.615 31.6798 496.578 30.5542 496.578 27.532V4.48797C496.578 2.83111 497.922 1.48797 499.578 1.48797H509.154C510.811 1.48797 512.154 2.83111 512.154 4.48797V60C512.154 61.6568 510.811 63 509.154 63H498.514C497.475 63 496.51 62.4622 495.963 61.5784L479.69 35.2762C478.1 32.707 474.138 33.8335 474.138 36.8547V60C474.138 61.6568 472.795 63 471.138 63H461.562C459.906 63 458.562 61.6568 458.562 60V4.48797Z" fill="white"/>
				</svg>
			</button>
		</div>
		<ul class="dm-nav-ctnr" :class="this.isMenuOpen ? 'active' : ''">
			<li class="dm-nav-item">
				<a class="dm-nav-item-lnk" @click="this.toggleMenu()" href="https://www.microsoft.com/store/apps/9N2TN688BBXT" target="_parent" :draggable="false">
					<span class="dm-nav-icon">
						<CloudDownloadIcon></CloudDownloadIcon>
					</span>
					<span class="dm-nav-text">download</span>
				</a>
			</li>
			<li class="dm-nav-item">
				<span class="dm-nav-item-lnk" @click="this.scrollTo('features-darkmoon'); this.toggleMenu()" :draggable="false">
					<span class="dm-nav-icon">
						<ToolsIcon></ToolsIcon>
					</span>
					<span class="dm-nav-text">features</span>
				</span>
			</li>
			<li class="dm-nav-item">
				<a class="dm-nav-item-lnk" @click="this.toggleMenu()" href="https://bitbucket.org/dark-moon-cybersecurity/darkmoonv1.0.4" target="_parent" :draggable="false">
					<span class="dm-nav-icon">
						<BitbucketIcon></BitbucketIcon>
					</span>
					<span class="dm-nav-text">bitbucket</span>
				</a>
			</li>
			<li class="dm-nav-item">
				<a class="dm-nav-item-lnk" @click="this.toggleMenu()" href="https://darkmoon.freshdesk.com/" target="_parent" :draggable="false">
					<span class="dm-nav-icon">
						<HeadsetIcon></HeadsetIcon>
					</span>
					<span class="dm-nav-text">helpdesk</span>
				</a>
			</li>
			<li class="dm-nav-item">
				<a class="dm-nav-item-lnk" @click="this.toggleMenu()" href="https://dark-moon-x.github.io/DarkMoon/" target="_parent" :draggable="false">
					<span class="dm-nav-icon">
						<FileDocumentIcon></FileDocumentIcon>
					</span>
					<span class="dm-nav-text">docs</span>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
	import { ref } from 'vue';

	import BitbucketIcon from 'vue-material-design-icons/Bitbucket.vue';
	import CloseIcon from 'vue-material-design-icons/Close.vue';
	import CloudDownloadIcon from 'vue-material-design-icons/CloudDownload.vue';
	import FileDocumentIcon from 'vue-material-design-icons/FileDocument.vue';
	import HeadsetIcon from 'vue-material-design-icons/Headset.vue';
	import MenuIcon from 'vue-material-design-icons/Menu.vue';
	import ToolsIcon from 'vue-material-design-icons/Tools.vue';

	export default {
		name: 'NavBarComponent',
		components: {
			BitbucketIcon,
			CloseIcon,
			CloudDownloadIcon,
			FileDocumentIcon,
			HeadsetIcon,
			MenuIcon,
			ToolsIcon
		},
		data() {
			return {
				isMenuOpen: false
			}
		},
		mounted() {
			window.addEventListener("scroll", this.onScroll, true);
		},
		beforeUnmount() {
			window.removeEventListener("scroll", this.onScroll, true);
		},
		methods: {
			onScroll() { this.currentScroll = window.scrollY; },
			scrollToTop() { window.scroll({ top: 0, behavior: 'smooth' }); },
			scrollTo(el) { window.scroll({ top: document.getElementById(el).getBoundingClientRect().top - 90, behavior: 'smooth' }); },
			toggleMenu() { this.isMenuOpen = !this.isMenuOpen; }
		},
		setup() {
			return {
				currentScroll: ref(window.scrollY)
			}
		}
	}
</script>

<style lang="scss">
	.dm-nav {

		left: 50%;
		margin-top: 2rem;
		position: fixed;
		transform: translateX(-50%);
		transition: all .2s;
		z-index: 1;

		.dm-nav-mobile {

			$size: 4rem;

			background-color: var(--aside-bg);
			box-shadow: 0 0 20px rgba(0, 0, 0, .2);
			display: none;
			height: $size;
			overflow: hidden;
			position: absolute;
			width: 100vw;
			z-index: 1;

			.dm-nav-burger {

				cursor: pointer;
				display: block;
				height: $size;
				position: absolute;
				width: $size;

				.material-design-icon {

					left: calc(50% + .25rem);
					position: absolute;
					top: calc(50% + .25rem);
					transform: translate(-50%, -50%);

				}
			}

			.dm-nav-hero {

				align-items: center;
				background-color: var(--main-bg);
				border: none;
				border-radius: 50px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-content: center;
				height: $size - .5rem;
				left: 50%;
				overflow: hidden;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 10.5rem;

				svg {
					&:first-of-type {

						height: $size - 2.5rem;
						margin-right: .5rem;
						width: auto;

					}

					&:last-of-type { width: 6rem; }
				}
			}
		}

		&.edged {

			margin-top: 0;

			.dm-nav-ctnr {

				border-radius: 0;
				box-shadow: 0 0 .75rem var(--main-bg);
				max-width: none;
				width: 100vw;

				.dm-nav-item .dm-nav-item-lnk, .dm-nav-item-lnk:visited {

					$size: 4.5rem;

					height: $size;
					width: calc($size + 1rem);

				}
			}
		}

		.dm-nav-ctnr {

			align-items: center;
			background-color: var(--aside-bg);
			border-radius: var(--aside-radius);
			box-shadow: 0 0 0 var(--main-bg);
			display: flex;
			flex-direction: row;
			justify-content: center;
			list-style-type: none;
			max-width: 53.6875rem;
			overflow: hidden;
			transition: all .2s ease-in-out;
			width: calc(100vw - 3rem);

			.dm-nav-item {

				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 0 1rem;

				.dm-nav-item-lnk, .dm-nav-item-lnk:visited {

					$size: 5.5rem;

					align-items: center;
					cursor: pointer;
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: $size;
					width: $size;

					.dm-nav-icon {

						align-items: center;
						background-color: #fff;
						border-radius: 16px;
						color: #28292A;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						justify-content: center;
						padding: .25rem 1rem;

						.material-design-icon { height: 1.5rem; }

					}

					.dm-nav-text {

						font-size: .9rem;
						text-transform: capitalize;

					}
				}

				&:first-of-type > .dm-nav-item-lnk > .dm-nav-icon {

					background-color: var(--primary-color);
					color: var(--main-color);

				}
			}
		}
	}

	@media (max-width: 700px) {
		.dm-nav {

			margin-top: 0;
			left: 0;

			&.edged .dm-nav-ctnr {

				width: 0;

				.dm-nav-item .dm-nav-item-lnk, .dm-nav-item-lnk:visited {

					$size: 5.5rem;

					height: $size;
					width: calc($size + 1rem);

				}
			}

			.dm-nav-mobile { display: block; }

			.dm-nav-ctnr {

				border-radius: 0;
				box-shadow: 0 0 .75rem var(--main-bg);
				display: flex;
				flex-direction: column;
				height: calc(100vh);
				max-width: none;
				position: absolute;
				width: 0;

				&.active { width: 7rem; }
			}
		}
	}
</style>